import React from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Table,
} from "reactstrap";
import { Api } from "../../../../Api/index";
import SharedComponent from "../../Shared";

class Report extends SharedComponent {
  state = {
    displayRaw: false,
    openRecord: 0,
  };

  uppercase(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  prettyKey(key) {
    return key
      .replace(/([A-Z]+)/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();
  }

  renderData(data) {
    if (typeof data === "object") console.log("ITS ME", data);
    return data;
  }

  renderTable = (table) => {
    return (
      <>
        {(table.data.length !== 0 || table.tables.length !== 0) && (
          <ListGroupItem key={table.title || "r"}>
            {table.title && (
              <ListGroupItemHeading tag="h4">
                {table.title}
              </ListGroupItemHeading>
            )}
            <Table>
              {table.headers && table.headers.length !== 0 && (
                <thead>
                  <tr>
                    {table.headers.map((header, i) => (
                      <th key={`${table.title}-${header}-${i}`}>{header}</th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {table.data.map((datas, i) => (
                  <tr key={`${table.title}-datas-${i}`}>
                    {Array.isArray(datas) ? (
                      datas.map((data, i) => (
                        <td key={`${table.title}-datas-d-${i}`}>
                          {this.renderData(data)}
                        </td>
                      ))
                    ) : (
                      <td key={`${table.title}-datas-d-${i}`}>{datas}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </ListGroupItem>
        )}
        {table.tables &&
          table.tables.length > 0 &&
          table.tables.map(this.renderTable)}
      </>
    );
  };

  processArray(table, array, key) {
    // Handle array of primitives
    if (array.filter((f) => typeof f === "object").length === 0) {
      table.tables.push({
        title: this.prettyKey(key),
        data: array,
      });
      // Handle arrays of one item
    } else if (array.length === 1) {
      array[0] &&
        table.tables.push(this.process(array[0], this.prettyKey(key)));
      // Process array of objects
    } else {
      const arrayTable = {
        title: this.prettyKey(key),
        headers: [],
        data: [],
        tables: [],
      };

      console.log("Array Time", array);
      // Build all headers since objects within array could have different
      // data sets in them we want to ensure all keys are present in the headers
      // Once that is done then we can push the data in the right order and use an empty
      // string for objects that may not have the same data as another object in the array
      array.forEach((arrayObj) =>
        Object.keys(arrayObj).forEach((k) => {
          if (
            Array.isArray(arrayObj[k]) &&
            arrayObj[k].length < 0 &&
            typeof arrayObj[k][0] !== "string"
          )
            throw Error(`Can't support super nested yet - key=${k}`);
          const pk = this.prettyKey(k);
          if (!arrayTable.headers.includes(pk)) arrayTable.headers.push(pk);
        })
      );

      // Store an array of our nested objects so we can make one table at the end of going through
      // all of our array data
      const nestedObjects = [];

      // Now we can push the data and use the header array to determine what index
      // Of the array the data needs to be in
      array.forEach((arrayObj) => {
        const data = Array(arrayTable.headers.length).fill("");
        Object.keys(arrayObj).forEach((k) => {
          if (typeof arrayObj[k] === "object") {
            nestedObjects.push(arrayObj[k]);
          } else if (Array.isArray(arrayObj[k])) {
            data[arrayTable.headers.indexOf(this.prettyKey(k))] =
              arrayObj[k].join(", ");
          } else {
            data[arrayTable.headers.indexOf(this.prettyKey(k))] = arrayObj[k];
          }
        });
        arrayTable.data.push(data);
      });

      if (nestedObjects.length > 0)
        this.processArray(arrayTable, nestedObjects, "");

      table.tables.push(arrayTable);
    }
  }

  process = (obj, title = "") => {
    if (typeof obj !== "object")
      throw Error(`Can't process obj. Isn't a object`);

    const table = {
      title,
      headers: [],
      data: [[]],
      tables: [],
    };

    Object.keys(obj).map((key, i) => {
      // Events come back in an odd format to display nicely
      // So let's handle them manually
      if (key === "events") {
        if (!Array.isArray(obj[key])) throw Error(`${key} is not an array`);
        const eventTable = {
          title: this.prettyKey(key),
          headers: [],
          data: [],
          tables: [],
        };
        const eventTables = [];
        // Initialize tables for every event title found in events
        obj[key].forEach((event) => {
          const title = this.prettyKey(event.title.toLowerCase());
          let eventTable = eventTables.find((t) => t.title === title);
          if (!eventTable) {
            eventTable = {
              title,
              headers: [],
              data: [[]],
              tables: [],
            };
            eventTables.push(eventTable);
          }

          // Get all headers for this event
          Object.keys(event.other).forEach((key) => {
            const prettyKey = this.prettyKey(key);
            if (!eventTable.headers.includes(prettyKey))
              eventTable.headers.push(prettyKey);
          });
        });

        // Now that we have full table and headers we can add the data
        obj[key].forEach((event) => {
          const title = this.prettyKey(event.title.toLowerCase());
          const table = eventTables.find((t) => t.title === title);
          const data = Array(table.headers.length).fill("");
          Object.keys(event.other).forEach((k) => {
            data[table.headers.indexOf(this.prettyKey(k))] = event.other[k];
          });
          table.data.push(data);
        });

        eventTable.tables.push(...eventTables);
        table.tables.push(eventTable);
      } else if (Array.isArray(obj[key]) && obj[key].length > 0) {
        this.processArray(table, obj[key], key);
      } else if (typeof obj[key] === "object") {
        if (Object.keys(obj[key]).length > 0)
          table.tables.push(this.process(obj[key], this.prettyKey(key)));
        // Combine all our root level items into the main title
      } else if (!title) {
        table.title += `${table.title ? " | " : ""}${this.prettyKey(key)}: ${
          obj[key]
        }`;
      } else if (key) {
        table.headers.push(this.prettyKey(key));
        table.data[0].push(obj[key]);
      }
    });

    if (!title) console.info("Processed Report", table);

    return table;
  };

  renderResult() {
    const rootKeys = Object.keys(this.props.report).filter(
      (k) => typeof this.props.report[k] !== "object"
    );

    return (
      <Table dark={this.props.dark} className="mb-4">
        <thead>
          <tr>
            {rootKeys.map((key, i) => (
              <th key={`th=${i}`}>{this.prettyKey(key)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {rootKeys.map((key, i) => (
              <td key={`d-${i}`}>{this.renderData(this.props.report[key])}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ displayRaw: true });
    this.renderErrorAlert(
      "Data Error",
      "We encountered data on this MVR report that we were not expecting. Once you close this modal you will be shown the raw result we received from Victig. Please send the following information to your friendly neighborhood developer\n\nError: " +
        JSON.stringify({ error, errorInfo })
    );
  }

  convertResult() {
    switch (this.props.report.result) {
      case "hits":
        return "Fail";
      case "yes":
        return "Pass";
      default:
        return "Unknown";
    }
  }

  render() {
    const hasResults =
      this.props.report.results &&
      this.props.report.results.records &&
      this.props.report.results.records.length;
    console.log("Has Results? " + hasResults);
    const hasMultipleRecords =
      hasResults && this.props.report.results.records.length > 1;
    const result = this.convertResult();
    return (
      <Card>
        <CardBody>
          <CardTitle
            tag="div"
            className="d-flex justify-content-between align-items-center"
          >
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="m-0 mr-2">
                {this.props.report.displayName} {this.props.report.displayValue}
              </h3>
              <Badge
                color={
                  result === "Fail"
                    ? "danger"
                    : result === "Pass"
                    ? "success"
                    : "dark"
                }
              >
                {result}
              </Badge>
            </div>
            <Button
              color={!this.state.displayRaw ? "link" : "primary"}
              onClick={() =>
                this.setState({ displayRaw: !this.state.displayRaw })
              }
            >
              View {this.state.displayRaw ? "Formatted" : "Raw"} Report
            </Button>
          </CardTitle>
          {this.state.displayRaw && (
            <div className="mt-4">
              <pre>{JSON.stringify(this.props.report, null, 2)}</pre>
            </div>
          )}
          {!this.state.displayRaw && (
            <>
              {this.renderResult()}
              {hasMultipleRecords && <h4>Records</h4>}
              {hasResults &&
                this.props.report.results.records.map((r, i) => (
                  <div key={i}>
                    {hasMultipleRecords && (
                      <Alert
                        color={this.state.openRecord === i ? "primary" : "dark"}
                        onClick={() => this.setState({ openRecord: i })}
                        style={{ cursor: "pointer" }}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div>Record #{i + 1}</div>
                        {this.state.openRecord === i ? (
                          <FaCaretDown />
                        ) : (
                          <FaCaretUp />
                        )}
                      </Alert>
                    )}
                    <Collapse
                      isOpen={
                        this.state.openRecord === i || !hasMultipleRecords
                      }
                      className="bg-light p-2 rounded mb-4"
                    >
                      <ListGroup>{this.renderTable(this.process(r))}</ListGroup>
                    </Collapse>
                  </div>
                ))}
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

class MVRReport extends SharedComponent {
  state = {
    loading: true,
    disableButton: false,
    openReportId: null,
    report: null,
  };

  componentWillMount() {
    Api["@driveavva"].core.UsersManagement.Reports(
      this.props.userId,
      "vitig_mvr"
    )
      .then((r) => {
        console.log("Retrieve Report", r.data);
        this.setState({
          loading: false,
          report: r.data[0],
          // openReportId: r.data.length === 1 ? r.data[0].key : null,
        });
      })
      .catch((e) => {
        console.log(e);
        this.renderErrorAlert(
          "Encountered an error loading reports",
          e.message
        );
      });
  }

  getAlertText = () => {
    let message = "";
    // if (this.state.reports.length) {
    // 	message += `You last ran a report on ${new Date(
    // 		this.state.reports[0].dateExecuted
    // 	).toLocaleString()}. If you want to run another report press the button below.`;
    // }
    message +=
      " This action will cost no more than $6.00 and be ran via Victig";

    return message;
  };

  runReport = () =>
    new Promise((resolve, reject) => {
      this.setState({ disableButton: true });
      Api["@driveavva"].core.UsersManagement.RunReport(
        this.props.userId,
        "vitig_mvr"
      )
        .then((result) => {
          console.log("Ran Report", result);
          this.setState({
            report: result.data,
            disableButton: false,
          });
          resolve("Successfully received a MVR report");
        })
        .catch((e) => {
          console.log(JSON.stringify(e));
          this.setState({
            disableButton: false,
          });
          this.renderErrorAlert(
            "Encountered an error generating report",
            e.message
          );
        });
    });

  render() {
    return (
      <div>
        {this.state.loading && <div>Loading...</div>}
        {!this.state.loading && !this.state.report && (
          <div>
            <h3>MVR has not yet be ran for this individual.</h3>
            <Button
              color="primary"
              disabled={this.state.disableButton || this.state.loading}
              onClick={() =>
                this.renderConfirmAlert(this.getAlertText(), this.runReport)
              }
            >
              Generat{this.state.disableButton ? "ing" : "e"} MVR Report
            </Button>
          </div>
        )}
        {!this.state.loading && this.state.report && (
          <Report report={this.state.report} />
        )}
      </div>
    );
  }
}

export { MVRReport };
