/* eslint-disable no-lone-blocks */
import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Datatable from "../../Tables/Datatable";
import SharedComponent from "../Shared";
import {
  ImportModal,
  BannerModal,
  NotificationModal,
  ActiveBannersModal,
  CompleteDeliveryModal,
  StripeImportModal,
} from "./modals";
import ReactSwitch from "react-switch";
import { Link } from "react-router-dom";
import { Api } from "../../../Api/index";
import { formatDate, formatFullDate, formatPhone } from "./utils";
import { MemberActionsButton } from "./MemberActionsButton";

class MembersList extends SharedComponent {
  state = {
    isLoading: true,
    data: {
      SearchTerm: "",
    },
    showStakeholders: false,
    includeInactive: false,
    displayItems: [],
    items: [],
    dealId: "",
    importingDeal: false,
    importingStripe: false,
    actionDropdown: false,
    notifications: [],
    modal: "",
    sortBy: "phone",
    userActionDropdown: false,
    userId: false,
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    }.currentPage,
    phoneNumber: "", // used to set the value of the input field
    vehicleUnitId: "", // used to set the value of the input field
  };

  constructor(props) {
    super(props);
    this.inputPhoneRef = React.createRef();
    this.inputVUIRef = React.createRef();
  }

  componentDidMount() {
    this.queryItems();
  }

  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });
    Api["@driveavva"].new.Users.UserList()
      .then((response) => {
        let items = response;

        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
        this.filterItems(this.state.showStakeholders);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  filterItems = (showStakeholders, sortBy = "avva_id") => {
    this.setState({
      showStakeholders,
      sortBy,
      displayItems: this.state.items.filter(
        ({ is_stakeholder }) =>
          (is_stakeholder && showStakeholders) || !is_stakeholder
      ),
    });
  };

  handlePhoneChange = (e) => {
    const input = this.inputPhoneRef.current.value
      .replace(/\D/g, "") // remove non-digits
      .substring(0, 10); // limit to ten digits

    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    this.setState({ phoneNumber: `(${areaCode}) ${middle}-${last}` });

    if (input.length > 6) {
      this.setState({ phoneNumber: `(${areaCode}) ${middle} - ${last}` });
    } else if (input.length > 3) {
      this.setState({ phoneNumber: `(${areaCode}) ${middle}` });
    } else if (input.length > 0) {
      this.setState({ phoneNumber: `(${areaCode}` });
    } else this.setState({ phoneNumber: "" });
  };

  handleVUIChange = (e) => {
    const input = this.inputVUIRef.current.value.substring(0, 8);
    this.setState({ vehicleUnitId: input });
  };

  doSubmit = () => {
    this.queryItems(1);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { phoneNumber, vehicleUnitId } = this.state;
    const phoneSearchTerm = `1${phoneNumber.replace(/\D/g, "")}`;
    const searchParams = new URLSearchParams(
      `phone=${phoneSearchTerm}&vui=${vehicleUnitId}`
    );
    window.location.search = searchParams.toString();
  };

  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.SearchTerm = "";
    this.setState({ requestOptions, data, phoneNumber: "", vehicleUnitId: "" });
    this.queryItems();
  };

  importFromDealId = (dealId) => {
    this.setState({ importingDeal: true });
  };

  getNotifications = () => {
    Api["@driveavva"].new.Users.Notifications()
      .then((res) => {
        this.setState({ notifications: res });
      })
      .catch(() => {
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, not able to get Notifications!"
        );
      });
  };

  displayDevice = (device) => {
    if (!device) return <p>App not installed</p>;
    return (
      <>
        <p>
          {device.device_brand} ({device.device_os}) {device.device_model}
        </p>
        <div>
          Onboarding Completed At:{" "}
          {device.onboarding_completed_at
            ? new Date(device.onboarding_completed_at + "Z").toLocaleString()
            : "not completed"}
        </div>
        <div>
          Installed At: {new Date(device.installed_at + "Z").toLocaleString()}
        </div>
        <div>
          Version: {device.native_version} <i>{device.update_id}</i>
        </div>
      </>
    );
  };

  displaySettings = (settings) => {
    const r = [
      ["Location", "n/a"],
      ["Notifications", "n/a"],
      ["Biometric", "n/a"],
    ];

    settings.forEach((s) => {
      const type = s.type.toLowerCase();
      const f = r.findIndex(([name]) => type.startsWith(name.toLowerCase()));
      if (f !== -1)
        r[f] = [
          r[f][0],
          s.value === "true" ? "✅" : s.value === "false" ? "🚫" : "n/a",
        ];
    });

    return r.map(([name, v]) => (
      <p key={name}>
        <span>{name}:</span>
        <span className="ml-1">{v}</span>
      </p>
    ));
  };

  displayVehicle = (vehicle) => {
    if (!vehicle) return null;

    const averageOverage =
      vehicle.mileage_daily_average_amount -
      vehicle.mileage_rec_daily_average_amount;
    const milesColor =
      averageOverage > 0
        ? "danger"
        : averageOverage > -5
        ? "warning"
        : "success";

    return (
      <div className="d-flex">
        <img
          src={vehicle.vehicle_photo_url}
          alt="vehicle"
          height="70"
          className="mr-3"
        />
        <div>
          <div>
            <b>{vehicle.vehicle_name} </b>
            <span>
              ({vehicle.vehicle.unit_id}) ({vehicle.license_plate_number})
            </span>
          </div>
          <div>
            <b>{vehicle.roadside_provider_type} </b>
            <span>({vehicle.roadside_contract_number})</span>
          </div>
          <div>
            <b>Policy #{vehicle.insurance_policy_number} </b>
            <span>
              {this.renderDate(vehicle.insurance_policy_starts_at)} -{" "}
              {this.renderDate(vehicle.insurance_policy_ends_at)}
            </span>
          </div>
          {vehicle.mileage_at_delivery_amount ? (
            vehicle.mileage_updated_at ? (
              <>
                <div>
                  <b>Avg Daily</b>:{" "}
                  <span className={`text-${milesColor}`}>
                    {vehicle.mileage_daily_average_amount.toLocaleString()} mi
                  </span>
                  <b className="ml-2">Rec Daily</b>:{" "}
                  <span>
                    {vehicle.mileage_rec_daily_average_amount.toLocaleString()}{" "}
                    mi
                  </span>
                </div>
                <div>
                  <b>Driven</b>:{" "}
                  <span>
                    {vehicle.mileage_driven_amount.toLocaleString()} mi
                  </span>
                  <b className="ml-2">Remaining</b>:{" "}
                  <span>
                    {vehicle.mileage_remaining_amount.toLocaleString()} mi
                  </span>
                </div>
                <div>
                  {vehicle.mileage_updated_at && (
                    <>
                      <b>Updated</b>:{" "}
                      {new Date(
                        vehicle.mileage_updated_at + "Z"
                      ).toLocaleTimeString()}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div>Performing Initial Calculations</div>
            )
          ) : (
            <div>
              <Button
                color="danger"
                onClick={() =>
                  this.setState({
                    modal: "delivery",
                    vehicleId: vehicle.id,
                  })
                }
              >
                Complete Delivery
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, data } = this.state;
    {
      if (data) {
        return (
          <>
            <ImportModal
              isOpen={this.state.modal === "import"}
              onClose={() => this.setState({ modal: "" })}
            />
            <StripeImportModal
              isOpen={this.state.modal === "import-stripe"}
              onClose={() => this.setState({ modal: "" })}
            />
            <ActiveBannersModal
              isOpen={this.state.modal === "banners"}
              onClose={() => this.setState({ modal: "", userId: false })}
              userId={this.state.userId}
            />
            {this.state.vehicleId && (
              <CompleteDeliveryModal
                isOpen={this.state.modal === "delivery"}
                onClose={() => this.setState({ modal: "", vehicleId: false })}
                vehicle={this.state.vehicleId}
              />
            )}
            {this.state.userId && (
              <NotificationModal
                isOpen={this.state.modal === "notification"}
                user_id={this.state.userId}
                onClose={() => this.setState({ modal: "", userId: false })}
                notifications={this.state.notifications}
              />
            )}
            <div className="content-heading d-flex justify-content-between p-1-1 align-items-center">
              <h3 className="m-0">Members List</h3>
              <div className="d-flex align-items-center">
                <FormGroup className="d-flex align-items-center mr-4">
                  <span className="mr-1">Include Stakeholders?</span>
                  <ReactSwitch
                    checked={this.state.showStakeholders}
                    onChange={this.filterItems}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label className="m-0" for="sortBy">
                    Sort By
                  </Label>
                  <Input
                    type="select"
                    name="sortBy"
                    onChange={(e) => {
                      this.filterItems(
                        this.state.showStakeholders,
                        e.currentTarget.value
                      );
                    }}
                  >
                    <option value="phone">Phone</option>
                    <option value="name">Name</option>
                    <option value="vin">VIN</option>
                    <option value="installed">App Installed</option>
                  </Input>
                </FormGroup> */}
                <Button
                  onClick={() => this.setState({ modal: "import-stripe" })}
                >
                  Import Member
                </Button>
              </div>
            </div>
            {!isLoading ? (
              this.state.displayItems &&
              this.state.displayItems.length === 0 ? (
                this.renderSadFace(false)
              ) : (
                <>
                  <Card className="card-default no-border">
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Datatable options={this.renderOption()}>
                            <table className="table table-bordered table-striped table-hover mb-4 w-100">
                              <thead>
                                <tr>
                                  <th className="sort-alpha">User</th>
                                  <th className="sort-alpha">Vehicle</th>
                                  <th className="sort-alpha">Device</th>
                                  <th className="sort-alpha">Permissions</th>
                                  <th className="sort-alpha">Actions</th>
                                </tr>
                              </thead>

                              <tbody>
                                {this.state.displayItems &&
                                  this.state.displayItems.map(
                                    (member, index) => {
                                      return (
                                        <tr key={index}>
                                          {/* User */}
                                          <td>
                                            <div>
                                              {member.role === "APPLICANT" && (
                                                <h4>DEACTIVATED</h4>
                                              )}
                                              <b>
                                                {member.profile.first_name}{" "}
                                                {member.profile.last_name}
                                              </b>
                                              <p>
                                                {member.phone && member.name ? (
                                                  <span>Phone: </span>
                                                ) : null}
                                                {member.phone
                                                  ? formatPhone(member.phone)
                                                  : "N/A"}
                                              </p>
                                              {member.email ? (
                                                <p>
                                                  <span>Email: </span>
                                                  {member.email}
                                                </p>
                                              ) : null}
                                              <p>
                                                <span>Id: </span>
                                                {member.avva_id
                                                  ? member.avva_id
                                                  : "NOT_SETUP"}
                                              </p>
                                              <p>
                                                <b>Coins</b>:{" "}
                                                {member.available_coin_amount}
                                                <i className="ml-2">
                                                  (Lifetime:{" "}
                                                  {member.lifetime_coin_amount})
                                                </i>
                                              </p>
                                            </div>
                                          </td>
                                          {/* Vehicle */}
                                          <td>
                                            {this.displayVehicle(
                                              member.vehicle
                                            )}
                                          </td>
                                          {/* Device */}
                                          <td>
                                            {this.displayDevice(member.device)}
                                          </td>
                                          {/* Permissions */}
                                          <td>
                                            {this.displaySettings(
                                              member.user_settings
                                            )}
                                          </td>

                                          {/* Action */}
                                          <td className="d-flex justify-content-end align-items-center">
                                            <div>
                                              <MemberActionsButton
                                                user={member}
                                              />
                                              <Link
                                                to={`/members/${member.id}`}
                                                color="warning"
                                                className="btn btn-warning d-inline-flex align-items-center ml-1"
                                              >
                                                Details
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </Datatable>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )
            ) : (
              this.renderLoader()
            )}
          </>
        );
      } else {
        return null;
      }
    }
  }
}

export { MembersList };
